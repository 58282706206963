import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Social from "app/components/Social"
import "css/Footer.css";
import logo from "assets/logo/logo.jpg";
import { Constants } from "app/Constants";

const PHONE_NUM = "📞 " + Constants.PHONE;
const EMAIL = Constants.EMAIL;
const EMAIL_HREF = "mailto:" + EMAIL;
const ADDRESS = Constants.ADDRESS;
const SERVICES = Constants.SERVICES;

const Footer = () => {
  const services = (
    <Col md={4}>
      <h4>Services</h4>
      <ul className={"list-unstyled"}>
        {SERVICES.map((line, index) => (
          <li key={index} className={"text-muted"}>
            {line}
          </li>
        ))}
      </ul>
    </Col>
  );

  const contact = (
    <Col md={4}>
      <h4>Contact</h4>
      <ul className={"list-unstyled"}>
        <li>{PHONE_NUM}</li>
        <li>
          <a href={EMAIL_HREF}>{EMAIL}</a>
        </li>
        {ADDRESS.map((line, index) => (
          <li key={index} className={"text-muted"}>
            {line}
          </li>
        ))}
      </ul>
    </Col>
  );

  return (
    <footer className={"footer-skyline"}>
      <Container className={"pt-4 text-white"}>
        <Row className={"justify-content-md-center"}>
          <Col md={4}>
            <img width={50} height={50} src={logo} alt="Icon" className="mb-2" />
            <p>
              &copy; 2016-2021 <br />
              Varmine Spaces
            </p>
            <Social/>
          </Col>
          {services}
          {contact}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
