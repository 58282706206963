import React from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Button as SexyButton } from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import Constants from "app/Constants";

import "css/Contact.css";
import "react-phone-input-2/lib/style.css";

type Segment = "" | "office" | "warehouse" | "residential";

type FormState = {
  name: string;
  phone: string;
  email: string;
  segment: Segment;
  query: string;
};

type ContactFormProps = {
  queryBoxRows?: number;
  theme?: "light" | "dark";
  queryPlaceholder?: string;
};

// The actual Contact Form.
class ContactForm extends React.Component<ContactFormProps, FormState> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      phone: "",
      email: "",
      segment: "office",
      query: "",
    };
  }

  resetForm() {
    this.setState({ name: "", email: "", query: "" });
  }

  render() {
    const nameRow = (
      <Form.Group as={Row}>
        <Form.Label column md={2}>
          Name
        </Form.Label>
        <Col>
          <Form.Control
            type="text"
            value={this.state.name}
            onChange={(event) => this.onFieldChange("name", event.target.value)}
            placeholder="John Doe"
          />
        </Col>
      </Form.Group>
    );

    const emailRow = (
      <Form.Group as={Row}>
        <Form.Label column md={2}>
          Email
        </Form.Label>
        <Col>
          <Form.Control
            type="email"
            value={this.state.email}
            onChange={(event) => this.onFieldChange("email", event.target.value)}
            placeholder="john.doe@gmail.com"
          />
        </Col>
      </Form.Group>
    );

    const buttonNames: Segment[] = ["office", "residential", "warehouse"];

    const buttons = buttonNames.map((name) => (
      <SexyButton
        type="button"
        color={this.state.segment === name ? "purple" : undefined}
        onClick={(_) => this.setState({ segment: name })}
      >
        {this.capitalize(name)}
      </SexyButton>
    ));

    const segmentRow = (
      <Form.Group as={Row}>
        <Form.Label column md={2}>
          Segment
        </Form.Label>
        <Col className="text-left ContactFormSexyButton">
          <SexyButton.Group fluid>
            {buttons[0]}
            <SexyButton.Or text="-" />
            {buttons[1]}
            <SexyButton.Or text="-" />
            {buttons[2]}
          </SexyButton.Group>
        </Col>
      </Form.Group>
    );

    const queryBoxRows = this.props.queryBoxRows || 4;
    const queryRow = (
      <Form.Group as={Row}>
        <Form.Label column md={2}>
          Message
        </Form.Label>
        <Col>
          <Form.Control
            as="textarea"
            rows={queryBoxRows}
            value={this.state.query}
            onChange={(event) => this.onFieldChange("query", event.target.value)}
            placeholder={this.props.queryPlaceholder || Constants.DEFAULT_QUERY_PLACEHOLDER}
          />
        </Col>
      </Form.Group>
    );

    const phoneRow = (
      <Form.Group as={Row}>
        <Form.Label column md={2}>
          Phone
        </Form.Label>
        <Col>
          <div className="ContactFormPhone">
            <PhoneInput
              country={"in"}
              value={this.state.phone}
              onChange={(phone) => this.onFieldChange("phone", phone)}
            />
          </div>
        </Col>
      </Form.Group>
    );

    const themeClass = this.props.theme === "dark" ? "ContactForm-White" : "";
    return (
      <Container fluid className={`ContactForm ${themeClass}`}>
        <Form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          {nameRow}
          {phoneRow}
          {emailRow}
          {segmentRow}
          {queryRow}

          <Button type="submit" variant="success" size="lg" className="float-right">
            Send Message
          </Button>
        </Form>
      </Container>
    );
  }

  private onFieldChange(field: string, value: string) {
    field === "name" && this.setState({ name: value });
    field === "phone" && this.setState({ phone: value });
    field === "email" && this.setState({ email: value });
    field === "query" && this.setState({ query: value });
  }

  private async handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    try {
      const response = await axios({
        method: "POST",
        url: "/api/contact/save",
        data: this.state,
      });

      console.log(response);
      if (response.status === 200) {
        alert("Your message has been received.");
        this.resetForm();
      } else {
        console.log("Failed to submit message " + response.data);
        alert("Failed to submit message.");
      }
    } catch (e: any) {
      console.log(e.response);
      alert("Failed to contact server. " + e.response.data);
    }
  }

  private capitalize(name: string): string {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
}

// A small helper component.
const ContactGreeting = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <br />
      <h3>Leave us a brief message</h3>
      <h3>We shall get back to you soon!</h3>
      <h3>Or</h3>
      <h3>Send us a chat message.</h3>
    </div>
  );
};

// A wrapper component.
export const ContactSection = (props: ContactFormProps) => {
  return (
    <Container fluid id="contact" className="Contact SectionBorder">
      <Row className="justify-content-center">
        <Col md={4} lg={4} className="text-white">
          <ContactGreeting />
        </Col>

        <Col md={6} lg={5} className="border-left">
          <ContactForm {...props} />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
