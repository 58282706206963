import { Container, Row } from "react-bootstrap";
import MediaBox from "app/components/MediaBox";
import officeSpace from "assets/services/office.jpg";
import warehouse from "assets/services/warehouse.jpg";
import residential from "assets/services/residential.jpg";

import "css/Common.css";
import "css/App.css";

const subtitles = {
  office:
    "With 4+ years of experience, We have a sharp eye for matching your new office needs across 100+ properties in Bangalore.",
  residential:
    "Looking for an apt/house in Bangalore? Book a tour with us to find your new home; with our carefully handpicked selections.",
  warehouse:
    "Our knowledge and expertise in warehouse segment makes us a formidable partner for your next warehouse within/just outside the city of Bangalore.",
};

const Services = () => {
  return (
    <Container
      fluid
      className="OrangePurpleGradient SectionBorder text-white pb-5"
    >
      <a className="App-anchor" id="services" href="#" />
      <Row className="pt-4 justify-content-center">
        <h1>Our Services</h1>
      </Row>
      <Row className="mt-4 pb-4 justify-content-center">
        <MediaBox
          icon={officeSpace}
          title="Office Space"
          subtitle={subtitles.office}
          buttonLink="/office"
        />

        <MediaBox
          icon={residential}
          title="Residential"
          subtitle={subtitles.residential}
          buttonLink="/residential"
        />

        <MediaBox
          icon={warehouse}
          title="Warehouse"
          subtitle={subtitles.warehouse}
          buttonLink="/warehouse"
        />
      </Row>
    </Container>
  );
};

export default Services;
