export const Constants = {
  PHONE: "+91 90133-39787",
  EMAIL: "info@varmine.com",
  ADDRESS: [
    "365 Shared space, No #153",
    "Sector 5, 1st Block",
    "HSR Layout, Kormangla",
    "Bengaluru, Karnataka 560102."
  ],

  // Used in Footer
  SERVICES: [
    "Office Space",
    "Residential",
    "Warehousing",
    "Commercial Advisory Services",
  ],

  // Fb Chat plugin:
  CHAT_GREETING: "Welcome to Varmine, Please leave us a message!",
  CHAT_THEME_COLOR: "#33cc33",
  FB_PAGE_ID: "116936956382348",
  FB_APP_ID: "1281787392216796",

  // Contact Form:
  DEFAULT_QUERY_PLACEHOLDER: "Can I get a quote for a 10,000 ft² office space with 100 seats at the heart of Bangalore.",

  // Google Analytics:
  GOOGLE_ANALYTICS_ENABLE_GA4: true,
  GOOGLE_ANALYTICS_UA_ID: "UA-201287563-1",  // my ID
  // GOOGLE_ANALYTICS_GA4_ID: "G-JPG522R65Z",
  GOOGLE_ANALYTICS_GA4_ID: "G-T8KK03XNKK",   // Their ID
}

export default Constants;