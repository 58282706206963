import {
  faFacebook,
  faInstagram,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "css/components/ComponentsCommon.css";
import React from "react";

const Social = () => {
  return (
    <div className="social-container">
      <h4>Follow us on</h4>
      <a href="https://www.instagram.com/varmine_spaces/">
        <FontAwesomeIcon
          icon={faInstagram}
          size="2x"
          color="white"
          className="faIconElement"
        />
      </a>
      <a href="https://www.linkedin.com/company/varmine-spaces/">
        <FontAwesomeIcon
          icon={faLinkedin}
          size="2x"
          color="white"
          className="faIconElement"
        />
      </a>
      <a href="https://www.facebook.com/VarmineSpaces/">
        <FontAwesomeIcon
          icon={faFacebook}
          size="2x"
          color="white"
          className="faIconElement"
        />
      </a>
    </div>
  );
};

export default Social;
