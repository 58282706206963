import { Col, Image, Button } from "react-bootstrap";

import "css/components/MediaBox.css";

type MediaBoxProps = {
  icon: string;
  title: string;
  subtitle: string;
  hideButton?: boolean;
  buttonText?: string;
  buttonLink?: string;
  colsLarge?: number;
};

const MediaBox = (props: MediaBoxProps) => {
  const buttonText = props.buttonText || "Learn More »";
  const buttonLink = props.buttonLink || "#";
  const button = props.hideButton ? (
    ""
  ) : (
    <Button size="sm" variant="success" type="href" href={buttonLink} className="float-to-bottom">
      {buttonText}
    </Button>
  );

  const lg = props.colsLarge || 3;

  return (
    <Col sm={4} md={3} lg={lg} className="MediaBox m-4">
      <div className="float-to-top">
        <Image className="mb-3" roundedCircle width="160" height="160" src={props.icon} />
        <h2>{props.title}</h2>
      </div>
      <p>{props.subtitle}</p>
      <p>{button}</p>
    </Col>
  );
};

export default MediaBox;
