import React from "react";

import BasicLayout from "app/layout/BasicLayout";
import ReactMarkdown from "react-markdown";

import officeImg from "assets/services/pages/office-space.jpg";
import warehouseImg from "assets/services/warehouse.jpg";
import retailImg from "assets/services/pages/residential-retail.jpg";
import gfm from "remark-gfm";

export const OfficePage = () => {
  const PAGE = `
# Office Spaces & Commercial Advisory

Have a professional staff and Looking for a base for your team; 
or want to boost productivity with managed co-working spaces solution for the whole team?

We can advice you with **variety** of flexible options of Workspaces suitable to **your requirements**. 
Further, we also deliver **interior customization** with our partners network.

### Core Services

> Varmine can help you find the right office solution ranging from:

-	Co-working spaces (for 6+ teams)
-	Business centre
-	Managed office space
-	Financial restructuring of your existing lease
-	Design and Interiors solutions to match your corporate culture

### Commercial Advisory Service

Our abilities extend across the whole range of commercial real estate transactions – 
beginning with vital strategizing and planning, portfolio examination, area and site determination, 
and extending to renting, purchasing, leasing, and valuation of real estate.

This makes us an important property consultants to reevaluate your deals for a second opinion. 
You can opt for a free no-strings-attached \`30min 1:1\` session to discuss your requirements.


**Drop us a note via the chat button on bottom-right of the page!**

`;

  return (
    <BasicLayout title={"Office Spaces & Commercial Advisory"} image={officeImg}>
      <ReactMarkdown plugins={[gfm]} children={PAGE} className="my-4 pb-5" />
    </BasicLayout>
  );
};

export const WarehousePage = () => {
  const PAGE = `
# Warehouse Segment

Our rich expertise in in warehouse segment makes us a formidable partner if you are 
looking for the right location for your next warehouse. 
You experience true excellence and flexibility in the warehouse landscape of Bangalore..

> Varmine can provide the following bundle of services in this strategic segment of the market

- Evaluate the current and future needs of the business
- Assess internal fulfilment costs against third party logistics costs to determine if outsourcing is right for you
- Development of RFP to bid to multiple fulfilment companies
- Detailed due diligence including and comparative cost analysis, and functional capabilities comparison of potential third party logistic companies - including freight
- Contract review and negotiations including statements of work and service level agreements

**Drop us a note via the chat button on bottom-right of the page!**
`;

  return (
    <BasicLayout title={"Warehouse"} image={warehouseImg}>
      <ReactMarkdown plugins={[gfm]} className="my-4 pb-5">
        {PAGE}
      </ReactMarkdown>
    </BasicLayout>
  );
};

export const ResidentialPage = () => {
  const PAGE = `
# Residential

We are also in the segment of marketing, selling and advisory of Residential assets. 
We cover areas of leasing, development, and investment, ensuring our clients receive the highest quality advice. 

> Benefits of choosing Varmine Spaces to hunt the gems in Bangalore

- We provide detailed information on single assets and portfolios, and top-end luxury properties. 
- We provide end to end recommendations, on the effective market process.
- Seamless transaction between the developer and buyer.
- Tech-enabled processes to expedite the transaction transparently and in a stipulated timeline.


**Drop us a note via the chat button on bottom-right of the page!**
`;

  return (
    <BasicLayout title={"Residential"} image={retailImg}>
      <ReactMarkdown plugins={[gfm]} className="my-4 pb-5">
        {PAGE}
      </ReactMarkdown>
    </BasicLayout>
  );
};
