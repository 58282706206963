import logo from "assets/logo/logo.jpg";
import "css/App.css";
import { Navbar, Nav, Container, Button } from "react-bootstrap";

type NavProps = { sticky?: boolean; styleBasic?: Boolean };

const Navigation = (props: NavProps) => {
  const stickyClassName = props.sticky ? "fixed-top" : "";

  const brandName = <span>{"Varmine Spaces"}</span>;

  const brand = (
    <Navbar.Brand href="/" className="Nav-brand">
      <img src={logo} alt="V " />
      {brandName}
    </Navbar.Brand>
  );

  const navItems = props.styleBasic ? (
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link eventKey="1" href="/">
          Home
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  ) : (
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link eventKey="1" href="#services">
          Services
        </Nav.Link>
        <Nav.Link eventKey="2" href="#why-varmine">
          Why varmine
        </Nav.Link>
      </Nav>

      <Nav>
      <Button variant="success" type="link" href="/contact#contact">Post Requirements</Button>{' '}
      </Nav>
    </Navbar.Collapse>
  );

  const toggler = props.styleBasic ? (
    <div />
  ) : (
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
  );

  return (
    <Navbar className={`Nav-bg ${stickyClassName}`} variant="dark" expand="lg">
      <Container className={"Nav-content"}>
        {brand}
        {toggler}
        {navItems}
      </Container>
    </Navbar>
  );
};

export default Navigation;
