import React from "react";

import Navigation from "app/components/Navigation";
import Footer from "app/components/Footer";
import { Container } from "react-bootstrap";
import defaultImage from "assets/services/pages/office-space.jpg";
import "css/App.css";
import "css/layout/BasicLayout.css";

type PageProps = {
  title?: string;
  subtitle?: string;
  image?: string;
  children: any;
};

const BasicLayout = (props: PageProps) => {
  const title = !props.title ? <div /> : <h1>{props.title}</h1>;
  const image = props.image || defaultImage;

  return (
    <div className={"App App-bg"}>
      <Navigation sticky={true} styleBasic />
      <Container fluid className="BasicLayout">
        <div className="BasicLayout-content mt-5">
          <div className="BasicLayout-img-with-text text-white">
            <img src={image} alt="" />
            {title}
          </div>
          <article className="BasicLayout-md markdown-body">{props.children}</article>
        </div>
        <div className="BasicLayout-footer">
          <Footer />
        </div>
      </Container>
    </div>
  );
};

export default BasicLayout;
