import React from "react";

import "css/App.css";

import Navigation from "app/components/Navigation";
import Gallerina from "app/components/Gallerina";
import Services from "app/components/Services";
import { ContactSection } from "app/components/Contact";
import Footer from "app/components/Footer";
import WhySection from "app/components/WhySection";
import { Ripple } from "react-preloaders";

function HomePage() {
  return (
    <div className={"App App-bg"}>
      <Navigation sticky={true} />
      <Gallerina />
      <Services />
      <WhySection />
      <ContactSection theme="dark" />
      <Footer />
      <Ripple time={300} background={"#0073ed"} color={"#ffffff"} />
    </div>
  );
}

export default HomePage;
