import React from "react";
import ReactDOM from "react-dom";

// UA-XXX
import ReactGA from "react-ga";
// G-XXX
import GA4React from "ga-4-react";

import { Location } from "history";
import { createBrowserHistory } from "history";
import "semantic-ui-css/semantic.min.css";
import "css/index.css";
import App from "app/App";
import * as serviceWorker from "./serviceWorker";

import { Constants } from "app/Constants";
import MessengerCustomerChat from "react-messenger-customer-chat";

const history = createBrowserHistory();

const getLoc = (location: Location) => {
  return location.pathname + location.hash ? location.hash : "";
};

const initGoogleUA = () => {
  ReactGA.initialize(Constants.GOOGLE_ANALYTICS_UA_ID);
  ReactGA.pageview(getLoc(history.location)); // Record a pageview for the given page
  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(getLoc(location)); // Record a pageview for the given page
  });
};

// GA4 automatically sends page view events. However, its not easy to see it via URL.
const initGA4 = async () => {
  const ga4react = new GA4React(Constants.GOOGLE_ANALYTICS_GA4_ID);
  await ga4react.initialize();

  // ga4.pageview(getLoc(history.location));
  // Initialize google analytics page view tracking
  // history.listen((location) => {
  //   ga4.pageview(getLoc(location)); // Record a pageview for the given page
  // });
};

// initGoogleUA();

const rootElement = document.getElementById("root");
const chatElement = document.getElementById("chat-box");

(async () => {
  try {
    if (Constants.GOOGLE_ANALYTICS_ENABLE_GA4) {
      await initGA4();
    } else {
      initGoogleUA();
    }
  } catch (e) {
    console.log("error initializing GA4", e);
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );

  ReactDOM.render(
    <MessengerCustomerChat
      appId={Constants.FB_APP_ID}
      pageId={Constants.FB_PAGE_ID}
      themeColor={Constants.CHAT_THEME_COLOR}
      loggedInGreeting={Constants.CHAT_GREETING}
      loggedOutGreeting={Constants.CHAT_GREETING}
    />,
    chatElement
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
