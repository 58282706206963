import React, { useState } from "react";

import "css/App.css";
import img1 from "assets/gallery/desk-1.jpg";
import img2 from "assets/gallery/desk-2.jpg";
import img3 from "assets/gallery/desk-3.jpg";
import { Carousel } from "react-bootstrap";

const Gallerina = () => {
const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e: any) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      nextIcon={null}
      prevIcon={null}
      interval={4000}
    >
      <Carousel.Item className={"Gallery-item"}>
        <img src={img1} alt="First slide" />

        <Carousel.Caption>
          <div className={"Gallery-title"}>
            <h2>Varmine Spaces</h2>
            <p>Get A Place To Work In A Single Go</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className={"Gallery-item"}>
        <img src={img2} alt="Second slide" />

        <Carousel.Caption>
          <div className={"Gallery-title"}>
            <h2>Varmine Spaces</h2>
            <p>Too Big? Too Small? Explore Resizing Options!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className={"Gallery-item"}>
        <img src={img3} alt="Third slide" />
        
        <Carousel.Caption>
          <div className={"Gallery-title"}>
            <h2>Varmine Spaces</h2>
            <p>We Create The Vibe For Your Team!</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Gallerina;
