import { Container, Row } from "react-bootstrap";
import MediaBox from "app/components/MediaBox";

import "css/components/Team.css";
import "css/Common.css";
import researchIcon from "assets/why/research.png";
import executionIcon from "assets/why/execution.png";
import zeroDownIcon from "assets/why/bullsEye.png";
import thumbsUpIcon from "assets/why/thumbsUp.png";


const WhySection = () => {
  const research = (
    <MediaBox
      icon={researchIcon}
      title="Research"
      subtitle={
        "We diligently research and catalog properties of bangalore and focus on matching your requirements."
      }
      hideButton
      colsLarge={2}
    />
  );

  const zeroDown = (
    <MediaBox
      icon={zeroDownIcon}
      title="Zero Down"
      subtitle={
        "We use technology to provide transparency & accessibility to all legal & complicance so you can zero down on your real estate."
      }
      hideButton
      colsLarge={2}
    />
  );

  const execution = (
    <MediaBox
      icon={executionIcon}
      title="Execution"
      subtitle={
        "Varmine Spaces uniquely connects the dots across property, legal, etc. to create an experiantial, immersive and engaging environment."
      }
      hideButton
      colsLarge={2}
    />
  );

  const happyCustomers = (
    <MediaBox
      icon={thumbsUpIcon}
      title="Happy Customers"
      subtitle={
        "We have delivered more than 100,000 sq ft of total office space & 100+ dream homes to our valued customers."
      }
      hideButton
      colsLarge={2}
    />
  );

  return (
    <Container
      fluid
      className="OrangePurpleGradient SectionBorder text-white pb-5"
    >
      <a className="Anchor" id="why-varmine" />
      <Row className="pt-5 justify-content-center">
        <h1>Why Varmine?</h1>
      </Row>
      <Row className="py-4 justify-content-center">
        <h3>Benefits of letting us join your real estate hunt</h3>
      </Row>
      <Row className="mt-4 pb-4 justify-content-center TeamImgBorder">
        {research}
        {zeroDown}
        {execution}
        {happyCustomers}
      </Row>
    </Container>
  );
};

export default WhySection;
