import BasicLayout from "app/layout/BasicLayout";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import ContactForm from "app/components/Contact";
import "css/pages/ContactPage.css";
import "css/Common.css";

export const ContactPage = () => {
  const PAGE = `
# Your Requirements

There is nothing more delightful for us than hearing from our customers about the joy their 
employees feel at **the new office**; seeing the satisfaction in your eyes 
when you unlock the door to **your new home** with pride; witnessing the 
growth of your supply chain with **the new warehouse** you bought with Varmine.

## Visualize Your Requirements

This is the most important part of your **real estate heist**. Clarifying what \`you need\`. 
It's often easier said than done, and also it can change when options are presented.

Your requirements need not be objectively framed with 20 questions; 
Just let us see the picture of the dream house/warehouse/office space 
that you are looking for. 

### What are my requirements?

Here is a list of parameters to think about when you are hunting real estate with us:

- Budget range
- Location: Distance to nearest mall, restaurants & urban entertainment. 
- Floor preferences (high-rise / ground)
- Don't want to spend the time and moeny in furnishing interiors?
  - Please describe some examples of interior setup to help us find the best fit.
- **Size:** Square feet often feels differently in our heads vs reality.
  - We would recommend [this wikiHow article](https://www.wikihow.com/Visualize-Square-Feet) to get a better visual understanding of \`How much is 1,000 sq feet?\`
- \`[office space]\` Approximate number & size of conference-rooms, cubicles, seminar rooms.
- \`[office space]\` \`[it]\` Do you need to install a server room?
- \`[office space]\` Electrical & Communications Infra.
- \`[warehouse]\` Distance from hot marketplaces/etc in the city.

---
`;

  const QUERY_PLACEHOLDER = `Please describe your requirements with more details as explained above.`;

  return (
    <BasicLayout title={"Post Your Requirements"}>
      <ReactMarkdown linkTarget="_blank" plugins={[gfm]} className="my-4">
        {PAGE}
      </ReactMarkdown>

      <div className="ContactPage-form ContactPageGradient" id="contact">
        <ContactForm theme="light" queryBoxRows={8} queryPlaceholder={QUERY_PLACEHOLDER} />
      </div>
    </BasicLayout>
  );
};

export default ContactPage;
