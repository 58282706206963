import "css/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "app/pages/HomePage";
import NotFound from "app/pages/NotFound";
import { OfficePage, WarehousePage, ResidentialPage } from "app/pages/ServicePages";
import ContactPage from "app/pages/ContactPage";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/office">
            <OfficePage />
          </Route>
          <Route path="/warehouse">
            <WarehousePage />
          </Route>
          <Route path="/residential">
            <ResidentialPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
